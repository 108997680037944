import React from "react";

function Header() {
  const toggle = () => {
    const menu = document.getElementById("mobile-menu-2");
    menu?.classList.toggle("hidden");
  };

  return (
    <header className="fixed w-full z-50">
      <nav className="border-gray-200 bg-white py-2.5 dark:bg-gray-900 shadow-md">
        <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between px-4">
          <a href="#home" className="flex items-center">
            <img
              src="./favicon.png"
              className="mr-3 h-6 sm:h-9"
              alt="SDS Deliver Logo"
            />
            <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
              SDS Deliver IO
            </span>
          </a>
          <div className="flex items-center lg:order-2 space-x-2">
            {/* 📞 CTA Button */}
            <a
              href="https://hub.sdsdeliver.io/contact-us"
              target="_blank"
              rel="noopener noreferrer"
              className="hidden sm:inline-block rounded-lg bg-purple-500 px-4 py-2 text-white font-semibold hover:bg-purple-800 transition lg:mr-2"
            >
             Get a quote
            </a>


            <button
              data-collapse-toggle="mobile-menu-2"
              type="button"
              className="inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 lg:hidden"
              aria-controls="mobile-menu-2"
              aria-expanded="false"
              onClick={toggle}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div
            className="hidden w-full items-center justify-between lg:order-1 lg:flex lg:w-auto"
            id="mobile-menu-2"
          >
            <ul className="mt-4 flex flex-col font-medium lg:mt-0 lg:flex-row lg:space-x-8">
              <li>
                <a
                  href="#home"
                  className="block rounded bg-purple-700 py-2 pl-3 pr-4 text-white dark:text-white lg:bg-transparent lg:p-0 lg:text-purple-700"
                  aria-current="page"
                >
                  Company
                </a>
              </li>
              <li>
                <a
                  href="#we-got-you"
                  className="block border-b border-gray-100 py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-purple-700 lg:dark:hover:bg-transparent lg:dark:hover:text-white"
                >
                  Products & Services
                </a>
              </li>
              <li>
                <a
                  href="#trusted-by"
                  className="block border-b border-gray-100 py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-purple-700 lg:dark:hover:bg-transparent lg:dark:hover:text-white"
                >
                  About
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
